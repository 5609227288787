<template>
	<div id="user-index">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
}
</script>
